// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import CoustomImg from "../CoustomImg/CoustomImg";
import HomeSlider from "../Home/HomeSlider";
import OurWork from "../OurWork/OurWork";
import moment from "moment";
import { Country, State, City } from "country-state-city";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import { Link } from "react-router-dom";
function WeddingMessageComponent() {
  // lostfoundlistpublic

  const lost_and_found_pub = useSelector((state) =>
    state?.allapi?.get_message_list ? state?.allapi?.get_message_list : {}
  );
  const lostfoundList = lost_and_found_pub?.docs ? lost_and_found_pub?.docs : 0;
  const [page, setPage] = useState(1);
  const [category, setCategory] = useState("");
  const [state, setState] = useState("");
  const [District, setDistrict] = useState("");
  const [Citys, setCity] = useState("");
  const [CitysList, setCitysList] = useState([]);
  const [pincode, setpincode] = useState("");
  const [search, setsearch] = useState("");
  const [gender, setgender] = useState("");
  console.log(lost_and_found_pub);
  const dispatch = useDispatch();
  const allCountry = Country.getAllCountries();
  const allStates = State.getAllStates();

  // console.log(allStates);
  console.log(CitysList);
  const statesindia =
    allStates &&
    allStates?.filter((data) => {
      return data.countryCode == "IN";
    });
  // console.log(statesindia);
  useEffect(() => {
    if (state) {
      console.log("sfdsfdskjfnsdnfn");
      // const statecode =
      //   statesindia &&
      //   statesindia?.filter((data) => {
      //     return data.name == state;
      //   });

      // console.log(state);
      const statecode = statesindia.find((data) => data.name == state);
      // console.log(statecode);
      const cityData = City.getCitiesOfState("IN", statecode?.isoCode);

      console.log(cityData);
      setCitysList(cityData);
    }
  }, [state]);

  // console.log(statesindia);
  // console.log(allCity);
  useEffect(() => {
    dispatch(
      allapiAction.messagelistpublic({ page: 1, category: "weddingWishes" })
    );
    // dispatch(allapiAction.getsliderList({ page: "lostandfound" }));
  }, []);

  const hendletosearche = (e) => {
    setsearch(e);
    // lostfoundlistsearchpublic
    setPage(1);

    var fanalquery = { page: page, key: e };

    if (category) {
      fanalquery.category = category;
    }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }

    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofiltercate = (e) => {
    setCategory(e);
    setPage(1);

    var fanalquery = { page: page, category: e };

    if (search) {
      fanalquery.key = search;
    }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofilterstate = (e) => {
    setState(e);
    setPage(1);

    var fanalquery = { page: page, state: e };
    if (category) {
      fanalquery.category = category;
    }
    if (search) {
      fanalquery.key = search;
    }
    // if (state) {
    //   fanalquery.state = state;
    // }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofilterdis = (e) => {
    setDistrict(e);
    setPage(1);
    setgender("");

    var fanalquery = { page: page, District: e };
    if (category) {
      fanalquery.category = category;
    }
    if (search) {
      fanalquery.key = search;
    }
    if (state) {
      fanalquery.state = state;
    }
    // if (District) {
    //   fanalquery.District = District;
    // }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofilterdisgender = (e) => {
    // setDistrict(e);
    setgender(e);
    setPage(1);

    var fanalquery = { page: page, gender: e };
    if (category) {
      fanalquery.category = category;
    }
    if (search) {
      fanalquery.key = search;
    }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofilterCity = (e) => {
    setCity(e);
    setPage(1);

    var fanalquery = { page: page, City: e };
    if (category) {
      fanalquery.category = category;
    }
    if (search) {
      fanalquery.key = search;
    }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    // if (City) {
    //   fanalquery.City = City;
    // }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofilterPin = (e) => {
    setpincode(e);
    setPage(1);

    var fanalquery = { page: page, pincode: e };
    if (category) {
      fanalquery.category = category;
    }
    if (search) {
      fanalquery.key = search;
    }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    // if (pincode) {
    //   fanalquery.pincode = pincode;
    // }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };

  const items = lost_and_found_pub?.totalDocs;
  const itemsPerPage = 12;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(items && items / itemsPerPage));
  }, [itemOffset, itemsPerPage, lost_and_found_pub, items]);

  const handlePageClick = (event) => {
    const data = event?.selected + 1;
    const newOffset = (event.selected * itemsPerPage) % items;
    setItemOffset(newOffset);
    setPage(data);
    dispatch(
      allapiAction.messagelistpublic({ page: data, category: "weddingWishes" })
    );
  };
  return (
    <div>
      <div className="ps-product-list ps-clothings mt-5 px-3">
        <div className="ps-section__header my-5 border border-primary">
          <h3>Wedding wishes</h3>
        </div>
        <div className="ps-block__right"></div>
      </div>
      {/* </div> */}

      <div className="container-fluid shewBox">
        <div className="row">
          {lostfoundList &&
            lostfoundList?.map((data, i) => {
              return (
                <div
                  className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6 mb-2"
                  key={i}
                >
                  <div className="recentlostfound">
                    <div className="ps-product ps-product--inner p-4">
                      <div className="ps-product__thumbnail">
                        <div
                          className={
                            data?.subcategoryId == "found"
                              ? "ps-product__badge hot"
                              : "ps-product__badge"
                          }
                        >
                          {data?.subcategoryId}
                        </div>
                        <Link to={"/message/" + data?.slug}>
                          {/* <img
                                src={URL?.API_BASE_URL + data?.image}
                                alt=""
                              /> */}
                          <CoustomImg
                            url={URL.API_BASE_URL + data?.imagefor}
                            altkey={data?.title}
                            w1={165}
                            w2={250}
                            h1={80}
                            h2={120}
                          />
                        </Link>
                      </div>
                      <div className="ps-product__container">
                        <div className="ps-product__content">
                          <Link
                            className="ps-product__title"
                            to={`/message/${data?.slug}`}
                          >
                            {data?.name}
                          </Link>

                          <div
                            className="ps-product__progress-bar ps-progress"
                            data-value="82"
                          >
                            {/* <p className="lostDate">
                              {moment(data?.createdAt).format(" DD/MM/YYYY ")}
                            
                            </p> */}
                            <p className="lostDate">
                              {data?.city}, {data?.state}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="container mt-20">
        <nav
          className="d-flex justify-content-between"
          aria-label="Page navigation"
        >
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handlePageClick}
            className="paging_simple_numbersc pagination"
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="Previous"
            renderOnZeroPageCount={null}
          />
        </nav>
      </div>
      <div className="mt-25">
        <OurWork />
      </div>
    </div>
  );
}

export default WeddingMessageComponent;
