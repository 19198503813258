// import { URL } from "@/Redux/common/url";
// import { useRouter } from "next/router";
import React from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { URL } from "../../Redux/common/url";
import { useNavigate } from "react-router-dom";
function Winner({ homedata }) {
  // Certificateslist
  const get_home_all_pro = homedata;
  const route = useNavigate();
  // const get_home_all_pro = useSelector((state) =>
  //   state?.allapi?.get_home_all_pro ? state?.allapi?.get_home_all_pro : {}
  // );

  const recentlisting = get_home_all_pro?.Certificateslist
    ? get_home_all_pro?.Certificateslist
    : [];

  const slidecount =
    get_home_all_pro?.Certificateslist?.length == 1
      ? 1
      : get_home_all_pro?.Certificateslist?.length > 6
        ? 6
        : get_home_all_pro?.Certificateslist?.length - 1;

  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow:
      get_home_all_pro?.Certificateslist?.length == 1
        ? 1
        : get_home_all_pro?.Certificateslist?.length > 6
          ? 6
          : get_home_all_pro?.Certificateslist?.length - 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:
            get_home_all_pro?.Certificateslist?.length == 1
              ? 1
              : get_home_all_pro?.Certificateslist?.length > 3
                ? 3
                : get_home_all_pro?.Certificateslist?.length - 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow:
            get_home_all_pro?.Certificateslist?.length == 1
              ? 1
              : get_home_all_pro?.Certificateslist?.length > 2
                ? 2
                : get_home_all_pro?.Certificateslist?.length - 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow:
            get_home_all_pro?.Certificateslist?.length == 1
              ? 1
              : get_home_all_pro?.Certificateslist?.length > 2
                ? 2
                : get_home_all_pro?.Certificateslist?.length - 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div className="ps-product-list ps-clothings mt-5  ">
        <div className="ps-section__header my-1 border border-primary">
          <h3>Winner</h3>
        </div>
        <div className="winner-back">
          {recentlisting?.length > 0 && (
            <Slider {...settings}>
              {recentlisting &&
                recentlisting?.map((data, i) => {
                  return (
                    <div className="p-10" key={i}>
                      <div
                        className="card"
                        onClick={() => {
                          route("/winner/" + data?.slug);
                        }}
                      >
                        <div className="frame">
                          <img
                            src={URL?.API_BASE_URL + data?.image}
                            alt=""
                            className="winner-img"
                          />
                        </div>
                        <div className="card-body p-0">
                          <div className="win_pra">
                            <p>{data?.name}</p>
                            <p>{data?.crType}</p>
                            <p>{data?.city}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Slider>
          )}
        </div>
      </div>
    </div>
  );
}

export default Winner;
