// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import CoustomImg from "../CoustomImg/CoustomImg";
import HomeSlider from "../Home/HomeSlider";
import OurWork from "../OurWork/OurWork";
import moment from "moment";
import { Country, State, City } from "country-state-city";
import { allapiAction } from "../../Redux/common/action";
import { Link } from "react-router-dom";
function AllCatemessage() {
  // lostfoundlistpublic

  const lost_and_found_pub = useSelector((state) =>
    state?.allapi?.get_message_list ? state?.allapi?.get_message_list : {}
  );
  const lostfoundList = lost_and_found_pub?.docs ? lost_and_found_pub?.docs : 0;
  const [page, setPage] = useState(1);
  const [category, setCategory] = useState("");
  const [state, setState] = useState("");
  const [District, setDistrict] = useState("");
  const [Citys, setCity] = useState("");
  const [CitysList, setCitysList] = useState([]);
  const [pincode, setpincode] = useState("");
  const [search, setsearch] = useState("");
  const [gender, setgender] = useState("");
  console.log(lost_and_found_pub);
  const dispatch = useDispatch();
  const allCountry = Country.getAllCountries();
  const allStates = State.getAllStates();

  // console.log(allStates);
  console.log(CitysList);
  const statesindia =
    allStates &&
    allStates?.filter((data) => {
      return data.countryCode == "IN";
    });
  // console.log(statesindia);
  useEffect(() => {
    if (state) {
      console.log("sfdsfdskjfnsdnfn");
      // const statecode =
      //   statesindia &&
      //   statesindia?.filter((data) => {
      //     return data.name == state;
      //   });

      // console.log(state);
      const statecode = statesindia.find((data) => data.name == state);
      // console.log(statecode);
      const cityData = City.getCitiesOfState("IN", statecode?.isoCode);

      console.log(cityData);
      setCitysList(cityData);
    }
  }, [state]);

  // console.log(statesindia);
  // console.log(allCity);
  useEffect(() => {
    // dispatch(allapiAction.messagelistpublic({ page: 1 }));
    dispatch(allapiAction.getsliderList({ page: "messageCategory" }));
  }, []);

  const hendletosearche = (e) => {
    setsearch(e);
    // lostfoundlistsearchpublic
    setPage(1);

    var fanalquery = { page: page, key: e };

    if (category) {
      fanalquery.category = category;
    }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }

    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofiltercate = (e) => {
    setCategory(e);
    setPage(1);

    var fanalquery = { page: page, category: e };

    if (search) {
      fanalquery.key = search;
    }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofilterstate = (e) => {
    setState(e);
    setPage(1);

    var fanalquery = { page: page, state: e };
    if (category) {
      fanalquery.category = category;
    }
    if (search) {
      fanalquery.key = search;
    }
    // if (state) {
    //   fanalquery.state = state;
    // }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofilterdis = (e) => {
    setDistrict(e);
    setPage(1);
    setgender("");

    var fanalquery = { page: page, District: e };
    if (category) {
      fanalquery.category = category;
    }
    if (search) {
      fanalquery.key = search;
    }
    if (state) {
      fanalquery.state = state;
    }
    // if (District) {
    //   fanalquery.District = District;
    // }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofilterdisgender = (e) => {
    // setDistrict(e);
    setgender(e);
    setPage(1);

    var fanalquery = { page: page, gender: e };
    if (category) {
      fanalquery.category = category;
    }
    if (search) {
      fanalquery.key = search;
    }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofilterCity = (e) => {
    setCity(e);
    setPage(1);

    var fanalquery = { page: page, City: e };
    if (category) {
      fanalquery.category = category;
    }
    if (search) {
      fanalquery.key = search;
    }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    // if (City) {
    //   fanalquery.City = City;
    // }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofilterPin = (e) => {
    setpincode(e);
    setPage(1);

    var fanalquery = { page: page, pincode: e };
    if (category) {
      fanalquery.category = category;
    }
    if (search) {
      fanalquery.key = search;
    }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    // if (pincode) {
    //   fanalquery.pincode = pincode;
    // }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };

  const items = lost_and_found_pub?.totalDocs;
  const itemsPerPage = 12;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(items && items / itemsPerPage));
  }, [itemOffset, itemsPerPage, lost_and_found_pub, items]);

  const handlePageClick = (event) => {
    const data = event?.selected + 1;
    const newOffset = (event.selected * itemsPerPage) % items;
    setItemOffset(newOffset);
    setPage(data);
    dispatch(allapiAction.messagelistpublic({ page: data }));
  };
  return (
    <div>
      <div className="ps-product-list ps-clothings mt-5 px-3">
        {/* <hr /> */}
        <div className="ps-section__header my-2 border border-primary">
          <h3>All Message</h3>
        </div>
        <div className="ps-block__right"></div>
      </div>
      {/* </div> */}

      <div className="container-fluid shewBox">
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 mb-2">
            <Link to={"/birthdaywishes"}>
              <img src="/img/message/Birthday.png" alt="" />
            </Link>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 mb-2">
            <Link to={"/weddingwishes"}>
              <img src="/img/message/MarriageAnniversaryWishes.png" alt="" />
            </Link>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 mb-2">
            <Link to={"/shoksandesh"}>
              <img src="/img/message/shoksandesh.png" alt="" />
            </Link>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 mb-2">
            <Link to={"/job-portal"}>
              <img src="/img/message/PostFreeJob.png" alt="" />
            </Link>
          </div>
        </div>
      </div>
      <div className="hometest">
        <HomeSlider />
      </div>
      <div className="mt-25">
        <OurWork />
      </div>
    </div>
  );
}

export default AllCatemessage;
