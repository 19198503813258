// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import CoustomImg from "../CoustomImg/CoustomImg";
import HomeSlider from "../Home/HomeSlider";
import OurWork from "../OurWork/OurWork";
import moment from "moment";
import { Country, State, City } from "country-state-city";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import { Link } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
function AllMessageComponent() {
  // lostfoundlistpublic

  const lost_and_found_pub = useSelector((state) =>
    state?.allapi?.get_message_list ? state?.allapi?.get_message_list : {}
  );
  const lostfoundList = lost_and_found_pub?.docs ? lost_and_found_pub?.docs : 0;
  const [page, setPage] = useState(1);
  const [category, setCategory] = useState("");
  const [state, setState] = useState("");
  const [District, setDistrict] = useState("");
  const [Citys, setCity] = useState("");
  const [CitysList, setCitysList] = useState([]);
  const [pincode, setpincode] = useState("");
  const [search, setsearch] = useState("");
  const [gender, setgender] = useState("");
  console.log(lost_and_found_pub);
  const dispatch = useDispatch();
  const allCountry = Country.getAllCountries();
  const allStates = State.getAllStates();

  const [stateselect, setstateselect] = useState(null);
  const [districtselect, setdistrictselect] = useState(null);
  const [cityselect, setcityselect] = useState(null);

  const loadOptionsState = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareaStates?key=${search}&page=${pages?.page}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.State;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };
  const loadOptionsDisrcit = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareaDistricts?key=${search}&page=${pages?.page}&States_id=${stateselect?.State}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.District;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };
  const loadOptionsCity = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareacity?key=${search}&page=${pages?.page}&District_id=${districtselect?.District}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.Area + ", " + data?.Pincode;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };
  const loadOptionsCityM = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareacity?key=${search}&page=${pages?.page}&District_id=${districtselect?.District}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.Area + ", " + data?.Pincode;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };

  const loadOptionsPalyer = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareaforproduct?key=${search}&page=${pages?.page}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label =
        data?.Area +
        ", " +
        data?.District +
        ", " +
        data?.State +
        ", " +
        data?.Pincode;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };
  // console.log(allStates);
  console.log(CitysList);
  const statesindia =
    allStates &&
    allStates?.filter((data) => {
      return data.countryCode == "IN";
    });
  // console.log(statesindia);
  useEffect(() => {
    if (state) {
      console.log("sfdsfdskjfnsdnfn");
      // const statecode =
      //   statesindia &&
      //   statesindia?.filter((data) => {
      //     return data.name == state;
      //   });

      // console.log(state);
      const statecode = statesindia.find((data) => data.name == state);
      // console.log(statecode);
      const cityData = City.getCitiesOfState("IN", statecode?.isoCode);

      console.log(cityData);
      setCitysList(cityData);
    }
  }, [state]);

  // console.log(statesindia);
  // console.log(allCity);
  useEffect(() => {
    if (cityselect) {
      dispatch(
        allapiAction.messagelistpublic({
          page: 1,
          category: "birthdayWishes",
          location: cityselect?._id,
        })
      );
    } else {
      dispatch(
        allapiAction.messagelistpublic({ page: 1, category: "birthdayWishes" })
      );
    }

    // dispatch(allapiAction.getsliderList({ page: "lostandfound" }));
  }, [cityselect]);

  const hendletosearche = (e) => {
    setsearch(e);
    // lostfoundlistsearchpublic
    setPage(1);

    var fanalquery = { page: page, key: e };

    if (category) {
      fanalquery.category = category;
    }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }

    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofiltercate = (e) => {
    setCategory(e);
    setPage(1);

    var fanalquery = { page: page, category: e };

    if (search) {
      fanalquery.key = search;
    }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofilterstate = (e) => {
    setState(e);
    setPage(1);

    var fanalquery = { page: page, state: e };
    if (category) {
      fanalquery.category = category;
    }
    if (search) {
      fanalquery.key = search;
    }
    // if (state) {
    //   fanalquery.state = state;
    // }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofilterdis = (e) => {
    setDistrict(e);
    setPage(1);
    setgender("");

    var fanalquery = { page: page, District: e };
    if (category) {
      fanalquery.category = category;
    }
    if (search) {
      fanalquery.key = search;
    }
    if (state) {
      fanalquery.state = state;
    }
    // if (District) {
    //   fanalquery.District = District;
    // }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofilterdisgender = (e) => {
    // setDistrict(e);
    setgender(e);
    setPage(1);

    var fanalquery = { page: page, gender: e };
    if (category) {
      fanalquery.category = category;
    }
    if (search) {
      fanalquery.key = search;
    }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofilterCity = (e) => {
    setCity(e);
    setPage(1);

    var fanalquery = { page: page, City: e };
    if (category) {
      fanalquery.category = category;
    }
    if (search) {
      fanalquery.key = search;
    }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    // if (City) {
    //   fanalquery.City = City;
    // }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };
  const hendletofilterPin = (e) => {
    setpincode(e);
    setPage(1);

    var fanalquery = { page: page, pincode: e };
    if (category) {
      fanalquery.category = category;
    }
    if (search) {
      fanalquery.key = search;
    }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    // if (pincode) {
    //   fanalquery.pincode = pincode;
    // }
    dispatch(allapiAction.lostfoundlistsearchpublic(fanalquery));
  };

  const items = lost_and_found_pub?.totalDocs;
  const itemsPerPage = 12;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(items && items / itemsPerPage));
  }, [itemOffset, itemsPerPage, lost_and_found_pub, items]);

  const handlePageClick = (event) => {
    const data = event?.selected + 1;
    const newOffset = (event.selected * itemsPerPage) % items;
    setItemOffset(newOffset);
    setPage(data);

    if (cityselect) {
      dispatch(
        allapiAction.messagelistpublic({
          page: data,
          category: "birthdayWishes",
          location: cityselect?._id,
        })
      );
    } else {
      dispatch(
        allapiAction.messagelistpublic({
          page: data,
          category: "birthdayWishes",
        })
      );
    }
    // dispatch(
    //   allapiAction.messagelistpublic({ page: data, category: "birthdayWishes" })
    // );
  };
  return (
    <div>
      <div className="ps-product-list ps-clothings mt-5 px-3">
        <div className="ps-section__header my-5 border border-primary">
          <div className="col-md-3 col-sm-3 col-4">
            {" "}
            <h3>Birthday Wishes</h3>
          </div>
          {/* <div className="col-md-3 col-sm-3 col-12">
            {" "}
            <AsyncPaginate
              value={stateselect}
              className="liceninput2"
              placeholder="State"
              loadOptions={loadOptionsState}
              onChange={setstateselect}
              additional={{
                page: 1,
              }}
            />
          </div>
          <div className="col-md-3 col-sm-3 col-12">
            {" "}
            <AsyncPaginate
              value={districtselect}
              className="liceninput2"
              placeholder="District"
              loadOptions={loadOptionsDisrcit}
              onChange={setdistrictselect}
              additional={{
                page: 1,
              }}
            />
          </div> */}
          <div className="col-md-3 col-sm-3 col-8">
            <AsyncPaginate
              value={cityselect}
              className="liceninput2"
              placeholder="Serach by your area"
              loadOptions={loadOptionsPalyer}
              onChange={setcityselect}
              additional={{
                page: 1,
              }}
            />
          </div>
        </div>
        <div className="ps-block__right"></div>
      </div>
      {/* </div> */}

      <div className="container-fluid shewBox">
        <div className="row">
          {lostfoundList &&
            lostfoundList?.map((data, i) => {
              return (
                <div
                  className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6 mb-2"
                  key={i}
                >
                  <div className="recentlostfound">
                    <div className="ps-product ps-product--inner p-4">
                      <div className="ps-product__thumbnail">
                        <div
                          className={
                            data?.subcategoryId == "found"
                              ? "ps-product__badge hot"
                              : "ps-product__badge"
                          }
                        >
                          {data?.subcategoryId}
                        </div>
                        <Link to={"/message/" + data?.slug}>
                          {/* <img
                                src={URL?.API_BASE_URL + data?.image}
                                alt=""
                              /> */}
                          <CoustomImg
                            url={URL.API_BASE_URL + data?.imagefor}
                            altkey={data?.title}
                            w1={200}   // New width for smaller screens
                            w2={300}   // New width for larger screens
                            h1={220}   // New height for smaller screens
                            h2={220}   // New height for larger screens
                          />
                        </Link>
                      </div>
                      <div className="ps-product__container">
                        <div className="ps-product__content">
                          <Link
                            className="ps-product__title"
                            to={`/message/${data?.slug}`}
                          >
                            {data?.name}
                          </Link>

                          <div
                            className="ps-product__progress-bar ps-progress"
                            data-value="82"
                          >
                            {/* <p className="lostDate">
                              {moment(data?.createdAt).format(" DD/MM/YYYY ")}
                            
                            </p> */}
                            <p className="lostDate">
                              {data?.city}, {data?.state}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="container mt-20">
        <nav
          className="d-flex justify-content-between"
          aria-label="Page navigation"
        >
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handlePageClick}
            className="paging_simple_numbersc pagination"
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="Previous"
            renderOnZeroPageCount={null}
          />
        </nav>
      </div>
      <div className="mt-25">
        <OurWork />
      </div>
    </div>
  );
}

export default AllMessageComponent;
