// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import CoustomImg from "../CoustomImg/CoustomImg";
import HomeSlider from "../Home/HomeSlider";
import OurWork from "../OurWork/OurWork";
import moment from "moment";
import { Country, State, City } from "country-state-city";
import { Modal } from "antd";
import TruncateText from "../TruncateText/TruncateText";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
function AllVideoCommmmCom() {
  // lostfoundlistpublic
  const lost_and_found_pub = useSelector((state) =>
    state?.allapi?.get_video_pagi_list ? state?.allapi?.get_video_pagi_list : {}
  );

  // console.log(lost_and_found_pub);
  const lostfoundList = lost_and_found_pub?.docs ? lost_and_found_pub?.docs : 0;
  const [page, setPage] = useState(1);
  const [category, setCategory] = useState("");
  const [state, setState] = useState("");
  const [District, setDistrict] = useState("");
  const [Citys, setCity] = useState("");
  const [CitysList, setCitysList] = useState([]);
  const [pincode, setpincode] = useState("");
  const [search, setsearch] = useState("");
  const [gender, setgender] = useState("");
  // console.log(lost_and_found_pub);
  const dispatch = useDispatch();
  const allCountry = Country.getAllCountries();
  const allStates = State.getAllStates();

  // console.log(allStates);
  // console.log(CitysList);
  const statesindia =
    allStates &&
    allStates?.filter((data) => {
      return data.countryCode == "IN";
    });
  // console.log(statesindia);
  useEffect(() => {
    if (state) {
      // console.log("sfdsfdskjfnsdnfn");
      // const statecode =
      //   statesindia &&
      //   statesindia?.filter((data) => {
      //     return data.name == state;
      //   });

      // console.log(state);
      const statecode = statesindia.find((data) => data.name == state);
      // console.log(statecode);
      const cityData = City.getCitiesOfState("IN", statecode?.isoCode);

      // console.log(cityData);
      setCitysList(cityData);
    }
  }, [state]);

  // console.log(statesindia);
  // console.log(allCity);
  useEffect(() => {
    dispatch(allapiAction.studentVideoPubAll({ page: 1 }));
    // dispatch(allapiAction.getsliderList({ page: "lostandfound" }));
  }, []);

  const hendletosearche = (e) => {
    setsearch(e);
    // lostfoundlistsearchpublic
    setPage(1);

    var fanalquery = { page: page, key: e };

    dispatch(allapiAction.studentVideoPubAll(fanalquery));
  };
  const hendletofiltercate = (e) => {
    setCategory(e);
    setPage(1);

    var fanalquery = { page: page, category: e };

    dispatch(allapiAction.studentVideoPubAll(fanalquery));
  };
  const hendletofilterstate = (e) => {
    setState(e);
    setPage(1);

    var fanalquery = { page: page, state: e };

    dispatch(allapiAction.studentVideoPubAll(fanalquery));
  };
  const hendletofilterdis = (e) => {
    setDistrict(e);
    setPage(1);
    setgender("");

    var fanalquery = { page: page, District: e };

    dispatch(allapiAction.studentVideoPubAll(fanalquery));
  };
  const hendletofilterdisgender = (e) => {
    // setDistrict(e);
    setgender(e);
    setPage(1);

    var fanalquery = { page: page };

    dispatch(allapiAction.studentVideoPubAll(fanalquery));
  };
  const hendletofilterCity = (e) => {
    setCity(e);
    setPage(1);

    var fanalquery = { page: page, City: e };

    dispatch(allapiAction.studentVideoPubAll(fanalquery));
  };
  const hendletofilterPin = (e) => {
    setpincode(e);
    setPage(1);

    var fanalquery = { page: page, pincode: e };

    dispatch(allapiAction.studentVideoPubAll(fanalquery));
  };

  const items = lost_and_found_pub?.totalDocs;
  const itemsPerPage = 12;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(items && items / itemsPerPage));
  }, [itemOffset, itemsPerPage, lost_and_found_pub, items]);

  const handlePageClick = (event) => {
    const data = event?.selected + 1;
    const newOffset = (event.selected * itemsPerPage) % items;
    setItemOffset(newOffset);
    setPage(data);
    dispatch(allapiAction.studentVideoPubAll({ page: data }));
    window.scrollTo(12, 0);
    // dispatch(productsAction.getallproduct(data))
    // return () => { }

    // if (course?.length == 0) {
    //   dispatch(
    //     productsAction.getfilterProduct({
    //       page: data,
    //       category_id: categoryId,
    //       priceFrom: startIng,
    //       priceTo: ending,
    //     })
    //   );
    //   return () => {};
    // } else {
    //   dispatch(
    //     productsAction.getfilterProduct({
    //       brand: course,
    //       page: data,
    //       category_id: categoryId,
    //       priceFrom: startIng,
    //       priceTo: ending,
    //     })
    //   );
    //   return () => {};
    // }

    // dispatch(postAssignmentAction.searchQuestions(data, id?.state?.searchkey, ""));
    // return () => { };
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videodetal, setvideodetal] = useState({});

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleView = (e) => {
    setIsModalOpen(true);
    setvideodetal(e);
  };
  return (
    <div>
      <Modal
        title="Video"
        open={isModalOpen}
        onOk={handleCancel}
        onCancel={handleCancel}
        width={800}
      >
        <iframe
          width="260"
          height="280"
          src={videodetal?.link}
          // src={"https://www.youtube.com/embed/LGtw6rpmXEw?si=lLE4ySa4rvreSq6t"}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </Modal>
      <div className="hometest">
        {/* <HomeSlider /> */}

        <div className="container-fluid bg-warning text-center text-light">
          <h3>All Video</h3>
        </div>
      </div>
      <div className="container my-4"></div>

      <div className="container-fluid shewBox">
        <div className="row">
          {lostfoundList &&
            lostfoundList?.map((data, i) => {
              // console.log(data);
              return (
                <div className="col-6 col-sm-3 videoBox mt-3" key={i}>
                  <div className="card ">
                    {/* <iframe
                            width="260"
                            height="180"
                            src={data?.link}
                            // src={"https://www.youtube.com/embed/LGtw6rpmXEw?si=lLE4ySa4rvreSq6t"}
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                          ></iframe> */}

                    <CoustomImg
                      url={
                        data?.thumbnail
                          ? URL.API_BASE_URL + data?.thumbnail
                          : ""
                      }
                      altkey={data?.title}
                      w1={165}
                      w2={450}
                      h1={80}
                      h2={190}
                    />
                    <div
                      className="playIcon"
                      onClick={() => {
                        handleView(data);
                      }}
                    ></div>
                    <div className="ps-product__container">
                      <div className="ps-product__content">
                        {/* <p>User Review</p> */}
                        {/* <h5>{data?.name}</h5> */}
                        <TruncateText text={data?.name} maxLength={25} />
                        <br />
                        <TruncateText text={data?.description} maxLength={25} />
                      </div>
                      {/* <div className="ps-product__content hover">
                  
                        
                      </div> */}
                    </div>
                  </div>{" "}
                </div>
              );
            })}
        </div>
      </div>
      <div className="container mt-20">
        <nav
          className="d-flex justify-content-between"
          aria-label="Page navigation"
        >
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handlePageClick}
            className="paging_simple_numbersc pagination"
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="Previous"
            renderOnZeroPageCount={null}
          />
        </nav>
      </div>
      <div className="mt-25">
        <OurWork />
      </div>
    </div>
  );
}

export default AllVideoCommmmCom;
