import React, { useEffect, useState } from "react";
// import dynamic from "next/dynamic";
// import { URL } from "@/Redux/common/url";
// const Cktest = dynamic(() => import("../cktetst/Cktest"), {
//   ssr: false,
// });
import ImageUploading from "react-images-uploading";
// import { useRouter } from "next/router";
import { toast } from "react-toastify";
// import Api from "@/Redux/common/api";
import { State, City } from "country-state-city";
import { URL } from "../../Redux/common/url";
import { useNavigate } from "react-router-dom";
import Api from "../../Redux/common/api";
import { AsyncPaginate } from "react-select-async-paginate";
import OurWork from "../OurWork/OurWork";
import ImageCropper from "../ImageCroper/ImageCropper";

function AddMessage() {
  const [lostfinddata, setlostfinddata] = useState({
    subCate: "",
    subsubCate: "",
    country: "India",
  });

  const [stateselect, setstateselect] = useState(null);
  const [districtselect, setdistrictselect] = useState(null);
  const [cityselect, setcityselect] = useState(null);

  const [mangeKey, setmangeKey] = useState(0);
  const [mangeKey2, setmangeKey2] = useState(0);

  useEffect(() => {
    setmangeKey(mangeKey + 1);
    setmangeKey2(mangeKey2 + 1);
    setdistrictselect(null);
    setcityselect(null);

    return () => {};
  }, [stateselect]);
  useEffect(() => {
    // setmangeKey(mangeKey + 1);
    setmangeKey2(mangeKey2 + 1);
    setcityselect(null);
    return () => {};
  }, [districtselect]);

  const loadOptionsState = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareaStates?key=${search}&page=${pages?.page}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.State;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };
  const loadOptionsDisrcit = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareaDistricts?key=${search}&page=${pages?.page}&States_id=${stateselect?.State}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.District;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };
  const loadOptionsCity = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareacity?key=${search}&page=${pages?.page}&District_id=${districtselect?.District}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.Area + ", " + data?.Pincode;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };

  const [state, setState] = useState("");
  const [CitysList, setCitysList] = useState([]);
  const allStates = State.getAllStates();
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [images2, setImages2] = useState([]);
  const statesindia =
    allStates &&
    allStates?.filter((data) => {
      return data.countryCode == "IN";
    });
  useEffect(() => {
    if (state) {
      const statecode = statesindia.find((data) => data.name == state);
      const cityData = City.getCitiesOfState("IN", statecode?.isoCode);
      setCitysList(cityData);
    }
  }, [state]);

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };
  const onChange2 = (imageList, addUpdateIndex) => {
    setImages2(imageList);
  };

  const hendletochange = (e) => {
    const { name, value } = e?.target;
    setlostfinddata({ ...lostfinddata, [name]: value });
  };

  const [aboutPage, setaboutPage] = useState("");
  const API_URl = URL.API_BASE_URL;
  const UPLOAD_ENDPOINT = "publicApi/productcon";

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("upload", file);
            fetch(`${API_URl}/${UPLOAD_ENDPOINT}`, {
              method: "post",
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({ default: `${res.url}` });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  const maxNumber = 1;

  const [fileName, setFileName] = useState("");
  const [fileContant, setfileContant] = useState("");
  const [finalImg, setfinalImg] = useState("");

  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [fileName2, setFileName2] = useState("");
  const [fileContant2, setfileContant2] = useState("");
  const [finalImg2, setfinalImg2] = useState("");

  const [croppedImageUrl2, setCroppedImageUrl2] = useState(null);
  const hendleTosubmit = async () => {
    const data = new FormData();
    try {
      if (!fileName) {
        toast.error("please select Image");
      } else if (!lostfinddata?.name) {
        toast.error("please enter name");
      } else if (!lostfinddata?.subCate) {
        toast.error("please select category ");
      } /* else if (images2?.length == 0) {
        toast.error("please select your image ");
      } */ else if (!stateselect?.State) {
        toast.error("please select state ");
      } else if (!districtselect?.District) {
        toast.error("please select district ");
      } else if (!cityselect?.Area) {
        toast.error("please select city ");
      } else {



        data.append("name", lostfinddata?.name);

        data.append("categoryId", lostfinddata?.subCate);
        data.append("subcategoryId", lostfinddata?.subsubCate);
        data.append("bethakno", lostfinddata?.bethakno);

        data.append("gender", lostfinddata?.gender);
        data.append("address", lostfinddata?.address);
        data.append("family", lostfinddata?.family);
        // data.append("gender", lostfinddata?.gender);

        data.append("city", cityselect?.Area);
        data.append("state", stateselect?.State);
        data.append("district", districtselect?.District);
        data.append("location_id", cityselect?._id);

        data.append("country", lostfinddata?.country);

        data.append("description", lostfinddata?.description);

        data.append("imagefor", fileContant, fileName);
        // data.append("imagefor", images[0]?.file);
        // if (images2?.length > 0) {
        //   data.append("images", images2[0]?.file);
        // }
        if (fileName2) {
          data.append("images", fileContant2, fileName2);
          // data.append("images", images2[0]?.file);
        }

        const token = localStorage.getItem("access_token");
        const config = {
          headers: {
            Authorization: "Bearer " + token,
          },
        };

        if (localStorage.getItem("access_token")) {
          const response = await Api.post(`${URL.messagesubmit}`, data, config);

          if (response?.data?.success) {
            toast.success(response?.data?.message);
            navigate("/");
          } else {
            toast.error(response?.data?.message);
          }
        } else {
          const token = localStorage.getItem("access_token_vendor");
          const config = {
            headers: {
              Authorization: "Bearer " + token,
            },
          };
          const response = await Api.post(
            `${URL.messagesubmitSeller}`,
            data,
            config
          );

          if (response?.data?.success) {
            toast.success(response?.data?.message);
            navigate("/");
          } else {
            toast.error(response?.data?.message);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {}, []);
  return (
    <div>
      <h2 className="text-center animated_text ">Message</h2>
      <div className="container mt-5 lost_fount_form p-20" id="box">
        <div className="form-row"></div>
        <div className="form-row mt5">
          <div className="form-group col-md-3 col-sm-3">
            <label for="exampleFormControlSelect1">Category *</label>
            <select
              className="form-control"
              id="exampleFormControlSelect1"
              name="subCate"
              onChange={(e) => {
                hendletochange(e);
              }}
            >
              <option value={""}>Select</option>
              <option value={"birthdayWishes"}>
                Birthday Wishes (जन्मदिन की शुभकामनाएं)
              </option>
              <option value={"weddingWishes"}>
                Wedding Wishes (शादी की शुभकामनाएं)
              </option>
              {/* <option value={"found"}>Anivarsriy Wish</option> */}
              <option value={"condolenceMessage"}>
                Condolence Message(शोक सन्देश)
              </option>
            </select>
          </div>
          {lostfinddata?.subCate == "condolenceMessage" && (
            <div className="form-group col-md-3 col-sm-3">
              <label for="exampleFormControlSelect1"> Sub Category *</label>
              <select
                className="form-control"
                id="exampleFormControlSelect1"
                name="subsubCate"
                onChange={(e) => {
                  hendletochange(e);
                }}
              >
                <option value={""}>Select</option>
                {/* <option value={"tiyekibaithak"}>
                  tiyekibaithak (तीये की बैठक)
                </option> */}
                <option value={"baithak"}>baithak (बैठक)</option>
                {/* <option value={"found"}>Anivarsriy Wish</option> */}
                <option value={"punyatithi"}>Punyatithi(पुण्यतिथि)</option>
                <option value={"Homage"}>Homage(श्रद्धांजलि)</option>
              </select>
            </div>
          )}
          {lostfinddata?.subsubCate == "baithak" && (
            <div className="form-group col-md-3 col-sm-3">
              <label for="exampleFormControlSelect1"> Select baithak *</label>
              <select
                className="form-control"
                id="exampleFormControlSelect1"
                name="bethakno"
                onChange={(e) => {
                  hendletochange(e);
                }}
              >
                <option value={""}>Select</option>
                {/* <option value={"tiyekibaithak"}>
                  tiyekibaithak (तीये की बैठक)
                </option> */}
                <option value={"प्रथम"}>Pratham (प्रथम)</option>
                <option value={"द्वितीय"}>Dwitiya (द्वितीय)</option>
                <option value={"तृतीय"}>Tritiya (तृतीय)</option>
                <option value={"चतुर्थ"}>Chaturthi (चतुर्थ)</option>
                <option value={"पंचमी"}>Panchami (पंचमी)</option>
                <option value={"षष्ठी"}>Shashti (षष्ठी)</option>
                <option value={"सप्तमी"}>Saptami (सप्तमी)</option>
                <option value={"अष्टमी"}>Ashtami (अष्टमी)</option>
                <option value={"नवमी"}>Navami (नवमी)</option>
                <option value={"दशमी"}>Dashami (दशमी)</option>
                <option value={"एकादशी"}>Ekadashi (एकादशी)</option>
                <option value={"द्वादशी"}>Dwadashi (द्वादशी)</option>
              </select>
            </div>
          )}
          {lostfinddata?.subsubCate == "punyatithi" && (
            <div className="form-group col-md-3 col-sm-3">
              <label for="exampleFormControlSelect1">
                {" "}
                Select punyatithi *
              </label>
              <select
                className="form-control"
                id="exampleFormControlSelect1"
                name="bethakno"
                onChange={(e) => {
                  hendletochange(e);
                }}
              >
                <option value={""}>Select</option>
                {/* <option value={"tiyekibaithak"}>
                  tiyekibaithak (तीये की बैठक)
                </option> */}
                <option value={"प्रथम"}>Pratham (प्रथम)</option>
                <option value={"द्वितीय"}>Dwitiya (द्वितीय)</option>
                <option value={"तृतीय"}>Tritiya (तृतीय)</option>
                <option value={"चतुर्थ"}>Chaturthi (चतुर्थ)</option>
                <option value={"पंचमी"}>Panchami (पंचमी)</option>
                <option value={"षष्ठी"}>Shashti (षष्ठी)</option>
                <option value={"सप्तमी"}>Saptami (सप्तमी)</option>
                <option value={"अष्टमी"}>Ashtami (अष्टमी)</option>
                <option value={"नवमी"}>Navami (नवमी)</option>
                <option value={"दशमी"}>Dashami (दशमी)</option>
                <option value={"एकादशी"}>Ekadashi (एकादशी)</option>
                <option value={"द्वादशी"}>Dwadashi (द्वादशी)</option>
              </select>
            </div>
          )}

          {lostfinddata?.subCate !== "weddingWishes" && (
            <div className="form-group col-md-3 col-sm-3">
              <label for="inputPassword4">Gender</label>
              <select
                className="form-control"
                id="exampleFormControlSelect1"
                name="gender"
                onChange={(e) => {
                  hendletochange(e);
                }}
              >
                <option value={""}>select</option>
                <option value={"male"}>Male</option>
                <option value={"female"}>Female</option>
              </select>
            </div>
          )}
          <div className="form-group col-md-3 col-sm-3">
            <label for="inputEmail4"> name </label>
            <input
              type="text"
              className="form-control"
              id="inputEmail4"
              name="name"
              onChange={(e) => {
                hendletochange(e);
              }}
            />
          </div>
          {lostfinddata?.subCate == "condolenceMessage" && (
            <div className="form-group col-md-3 col-sm-3">
              <label for="inputEmail4"> family </label>
              <input
                type="text"
                className="form-control"
                id="inputEmail4"
                name="family"
                onChange={(e) => {
                  hendletochange(e);
                }}
              />
            </div>
          )}

          <div className="form-group col-md-3 col-sm-3">
            <label className="form-label mb-7">
              <span className="required">Image</span>
              <i
                className="fa fa-exclamation-circle"
                data-bs-toggle="popover"
                data-bs-trigger="hover"
                data-bs-html="true"
                data-bs-content="Permission names is required to be unique."
              ></i>
            </label>

            <div>
              {/* <h1>Image Cropper</h1> */}
              <ImageCropper
                aspectRatio={4 / 3}
                // onImageCrop={handleImageCrop}
                setfileContant={setfileContant}
                setFileName={setFileName}
                setfinalImg={setfinalImg}
                aspectwidth={16}
                aspectheight={16}
                onCropComplete={setCroppedImageUrl}
              />

              {croppedImageUrl && (
                <div>
                  <img
                    src={croppedImageUrl}
                    alt="Cropped"
                    width={100}
                    height={100}
                  />
                </div>
              )}
              {/* {finalImg && (
                    <div>
                      <img
                        src={finalImg}
                        alt="Cropped"
                        width={100}
                        height={130}
                      />
                    </div>
                  )} */}
            </div>
            {/* <ImageUploading
              value={images}
              onChange={onChange}
              maxNumber={maxNumber}
              dataURLKey="data_url"
              acceptType={["png", "jpeg", "jpg", "webp"]}
            >
              {({
                imageList,
                onImageUpload,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                <div className="upload__image-wrapper">
                  <button
                    style={isDragging ? { color: "red" } : {}}
                    onClick={onImageUpload}
                    {...dragProps}
                    className="btn btn-secondary w-100"
                  >
                    Choose Image
                  </button>
                  &nbsp;
                  {imageList?.map((image, index) => (
                    <div key={index} className="image-item">
                      <img src={image.data_url} alt="" width="100" />
                      <div className="image-item__btn-wrapper">
                        <button
                          className="btn btn-secondary"
                          onClick={() => onImageUpdate(index)}
                        >
                          Update
                        </button>
                        &nbsp;
                        <button
                          className="btn btn-secondary"
                          onClick={() => onImageRemove(index)}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </ImageUploading> */}
          </div>
        </div>
        <div className="form-row mt-5">
          <div className="form-group col-md-3 col-sm-3">
            <label for="inputCity">Country</label>
            <input
              type="text"
              className="form-control"
              id="inputcountry"
              name="country"
              value={"India"}
            />
          </div>
          <div className="form-group col-md-3 col-sm-3">
            <label for="inputState">State</label>
            {/* <select
              id="inputState"
              className="form-control shadow-lg bg-white"
              onChange={(e) => {
                setState(e?.target?.value);
                hendletochange(e);
              }}
              name="state"
            >
              <option value={""}>Select State</option>

              {statesindia &&
                statesindia?.map((data, i) => {
                  return (
                    <option key={i} value={data?.name}>
                      {data?.name}
                    </option>
                  );
                })}
            </select> */}

            <AsyncPaginate
              value={stateselect}
              className="liceninput2"
              placeholder="State"
              loadOptions={loadOptionsState}
              onChange={setstateselect}
              additional={{
                page: 1,
              }}
            />
          </div>
          {stateselect && (
            <div className="form-group col-md-3 col-sm-3">
              <label for="inputCity">District</label>
              <AsyncPaginate
                key={mangeKey}
                value={districtselect}
                className="liceninput2"
                placeholder="District"
                loadOptions={loadOptionsDisrcit}
                onChange={setdistrictselect}
                additional={{
                  page: 1,
                }}
              />
              {/* <select
                id="inputState"
                className="form-control shadow-lg bg-white"
                onChange={(e) => {
                  hendletochange(e);
                }}
                name="city"
              >
                <option value={""}>Select city</option>

                {CitysList &&
                  CitysList?.map((data, i) => {
                    return (
                      <option key={i} value={data?.name}>
                        {data?.name}
                      </option>
                    );
                  })}
              </select> */}
            </div>
          )}
          {districtselect && (
            <div className="form-group col-md-3 col-sm-3">
              <label for="inputCity">City/Area</label>
              <AsyncPaginate
                key={mangeKey2}
                value={cityselect}
                className="liceninput2"
                placeholder="City"
                loadOptions={loadOptionsCity}
                onChange={setcityselect}
                additional={{
                  page: 1,
                }}
              />
              {/* <select
                id="inputState"
                className="form-control shadow-lg bg-white"
                onChange={(e) => {
                  hendletochange(e);
                }}
                name="city"
              >
                <option value={""}>Select city</option>

                {CitysList &&
                  CitysList?.map((data, i) => {
                    return (
                      <option key={i} value={data?.name}>
                        {data?.name}
                      </option>
                    );
                  })}
              </select> */}
            </div>
          )}
          <div className="form-group col-md-3 col-sm-3">
            <label for="inputEmail4"> Address </label>
            <input
              type="text"
              className="form-control"
              id="inputEmail4"
              name="address"
              onChange={(e) => {
                hendletochange(e);
              }}
            />
          </div>
          {lostfinddata?.subCate !== "condolenceMessage" && (
            <div className="form-group col-md-3 col-sm-3">
              <label className="form-label mb-7">
                <span className="required">Your Image</span>
                <i
                  className="fa fa-exclamation-circle"
                  data-bs-toggle="popover"
                  data-bs-trigger="hover"
                  data-bs-html="true"
                  data-bs-content="Permission names is required to be unique."
                ></i>
              </label>

              <div>
                {/* <h1>Image Cropper</h1> */}
                <ImageCropper
                  aspectRatio={4 / 3}
                  // onImageCrop={handleImageCrop}
                  setfileContant={setfileContant2}
                  setFileName={setFileName2}
                  setfinalImg={setfinalImg2}
                  aspectwidth={16}
                  aspectheight={16}
                  onCropComplete={setCroppedImageUrl2}
                />

                {croppedImageUrl2 && (
                  <div>
                    <img
                      src={croppedImageUrl2}
                      alt="Cropped"
                      width={100}
                      height={100}
                    />
                  </div>
                )}
                {/* {finalImg && (
                    <div>
                      <img
                        src={finalImg}
                        alt="Cropped"
                        width={100}
                        height={130}
                      />
                    </div>
                  )} */}
              </div>
              {/* <ImageUploading
                value={images2}
                onChange={onChange2}
                maxNumber={maxNumber}
                dataURLKey="data_url"
                acceptType={["png", "jpeg", "jpg", "webp"]}
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  <div className="upload__image-wrapper">
                    <button
                      style={isDragging ? { color: "red" } : {}}
                      onClick={onImageUpload}
                      {...dragProps}
                      className="btn btn-secondary w-100"
                    >
                      Choose Your Image
                    </button>
                    &nbsp;
                    {imageList?.map((image, index) => (
                      <div key={index} className="image-item">
                        <img src={image.data_url} alt="" width="100" />
                        <div className="image-item__btn-wrapper">
                          <button
                            className="btn btn-secondary"
                            onClick={() => onImageUpdate(index)}
                          >
                            Update
                          </button>
                          &nbsp;
                          <button
                            className="btn btn-secondary"
                            onClick={() => onImageRemove(index)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </ImageUploading> */}
            </div>
          )}
        </div>

        <div className="form-row mt5">
          <div className="form-group col-md-12 col-sm-12">
            <label for="inputEmail4">Description</label>
            <input
              type="text"
              className="form-control"
              id="inputEmail4"
              name="description"
              onChange={(e) => {
                hendletochange(e);
              }}
            />
          </div>
        </div>

        <button
          className="mt-3 btn btn-primary btn-md"
          onClick={() => {
            hendleTosubmit();
          }}
        >
          SAVE AND CONTINUE
        </button>
      </div>
      <OurWork />
    </div>
  );
}

export default AddMessage;
